body {
  margin: 0;
  background-color: #0a0a0f;
  color: #ffffff;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 80% 10%, 
      rgba(190, 55, 255, 0.15),
      transparent 60%),
    radial-gradient(circle at 20% 90%, 
      rgba(68, 160, 255, 0.15),
      transparent 60%),
    radial-gradient(circle at 50% 50%, 
      rgba(88, 30, 150, 0.1),
      transparent 70%);
  filter: blur(80px);
  opacity: 0.8;
  z-index: -1;
}

.App {
  position: relative;
  min-height: 100vh;
  z-index: 1;
}

/* Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}

/* Common page container styles */
.page-container {
  padding-top: 120px;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

/* Common section styles */
.section {
  margin: 60px 0;
}

/* Common heading styles */
.heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #fff, #999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Card styles */
.card {
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add subtle animation to the background */
@keyframes gradientMovement {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-2%, 2%);
  }
  100% {
    transform: translate(0, 0);
  }
}

body::before {
  animation: gradientMovement 15s ease-in-out infinite;
}

/* Remove the glass morphism background and border */
.card, .page-container > div, nav {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
